<template>
    <div id="edit-usuario" class="animated fadeIn">
      <loading :active.sync="loading"></loading>
        <div class="row"><div class="col-md-12">
          <b-card>
<div slot="header">
            <strong>Editar usuário {{ usuario.name }}</strong>
          </div>
        <p v-if="!r.isCliente"><router-link :to="{ name: 'Listar Usuários' }">Listar usuários</router-link></p>

        <form v-on:submit.prevent="editUsuario">
            <div class="row">
                <div class="col-md-4">
                    <div v-if="!image">
                        <img :src="usuariodata.usr_img" class="img-thumbnail" />
                        <hr><input type="file" @change="onFileChange">
                        <br><small>Utilize apenas imagens com até 100KB de tamanho</small>
                    </div>
                    <div v-else>
                        <img v-if="image" :src="image" class="img-thumbnail" />
                        <img v-else :src="usuariodata.usr_img" class="img-thumbnail" />
                        <hr><button @click="removeImage">Trocar imagem</button>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group">
                        <label name="name"><strong>Apelido</strong></label>
                        <input type="text" class="form-control" v-model="usuario.name" id="name">
                    </div>
                    
                    <div class="form-group">
                        <label name="email"><strong>E-mail</strong></label>
                        <input type="email" class="form-control" v-model="usuario.email" id="email" required>
                    </div>

                    <div class="form-check">
                        <label class="form-check-label">
                        <input type="checkbox" v-model="set_password" value="1" class="form-check-input">
                        Redefinir senha?
                        </label>
                    </div>
                    <div class="container" v-if="set_password">
                    <div class="form-group">
                        <label name="password"><strong>Nova senha</strong></label>
                        <input type="password" class="form-control" v-model="usuario.password" id="password">
                    </div>

                    <div class="form-group">
                        <label name="password2"><strong>Redigite a nova senha</strong></label>
                        <input type="password" class="form-control" v-model="usuario.password2" id="password2">
                    </div>
                    </div>
                    <div class="form-check">
                        <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="usuario.is_active">
                        Ativar este usuário
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="container">
                    <div class="form-group">
                        <label name="first_name">Nome</label>
                        <input type="text" class="form-control" v-model="usuario.first_name" id="first_name">
                    </div>
                    <div class="form-group">
                        <label name="last_name">Sobrenome</label>
                        <input type="text" class="form-control" v-model="usuario.last_name" id="last_name">
                    </div>
                    <div class="form-group">
                        <label name="phone">Telefone</label>
                        <input type="tel" v-mask="['(##) ####-####', '(##) #####-####']" class="form-control" v-model="usuario.phone" id="phone">
                    </div>
                    
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="container">
                    <div class="form-group">
                        <label name="area"><i v-if="loading" class="fa fa-spin fa-cog"></i>Área</label>
                        <b-form-select v-model="usuarioArea" :options="asAreas"></b-form-select>
                    </div>
                    <div class="form-group">
                        <label name="area"><i v-if="loading" class="fa fa-spin fa-cog"></i>Papel</label>
                        <b-form-select v-model="usuarioPapel" :options="osPapeis"></b-form-select>
                    </div>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="!r.isCliente">
                <button class="btn btn-primary">Salvar dados</button>
            </div>
        </form>
        </b-card>
        </div></div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import Loading from 'vue-loading-overlay'
export default {
  data () {
    return {
      usuariodata: { usr_erp_login: '', usr_erp_password: '', usr_img: 'static/img/semfoto.jpg' },
      usuarioextra: {SELLER_USER_FK: null, CLIENT_CONTACT_GENERAL_FK: null, CONTACT_CONTACT_GENERAL_FK: null},
      usuario: {},
      ids: [],
      ownerid: this.$session.get('id'),
      image: new Image(),
      set_erp_password: 0,
      set_password: 0,
      contatos: [{value: null, label: ' - não selecionado -'}],
      empresas: [{value: null, label: ' - não selecionado -'}],
      usuarios: [{value: null, label: ' - não selecionado -'}],
      contatosAll: [],
      empresasAll: [],
      usuariosAll: [],
      usuarioArea: null,
      usuarioPapel: null,
      asAreas: [],
      osPapeis: [],
      loading: false,
      onde: '',
      r: {isAdmin: false, clientes: null, usuarios: null, isCliente: true}
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('ADMINISTRATIVO'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.image = ''
        this.verPerm()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    verPerm: function () {
      this.permissao_area().then(
        (response) => {
          this.r = response
          this.puxaListas()
        },
        (response) => {
          this.$notify({
            type: 'error',
            title: 'Falha nas permissões',
            text: 'Não foi possível continuar devido a uma falha nas permissões do sistema.'
          })
        }
      )
    },
    onFileChange: function (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.createImage(files[0])
    },
    createImage: function (file) {
      var reader = new FileReader()
      reader.onload = (e) => {
        this.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    removeImage: function (e) {
      this.image = ''
    },
    getUsuario: function () {
      return this.listar('user', '', this.$route.params.id, 'system').then(
        (response) => {
          this.usuario = response
          return response
        }, (response) => {
          return null
        })
    },
    getUsuarioData: function (tipo) {
      var v = 'usr_' + tipo
      return this.campo('gp_User', v, 'api_ID=' + this.$route.params.id).then(
        (response) => {
          this.usuariodata[v] = response
          return response
        }, (response) => { 
          this.usuario[v] = ''
          return false
        }
      )
    },
    getUsuarioArea: function () {
      // for (var i = 0; i < this.asAreas.length; i++) {
      return this.listar('filtro_User_Grupo', 'ugrp_ID', '(api_ID = ' + this.$route.params.id + ')').then(
        (response) => {
          if (response.length > 0) {
            for (var i = 0; i < response.length; i++) {
              if (response[i].grp_ID > 7) {
                this.usuarioArea = response[i].grp_ID
              } else {
                this.usuarioPapel = response[i].grp_ID
              }
            }
          }
          return true
        },
        (response) => {
          this.usuarioArea = null
          this.usuarioPapel = null
          return false
        }
      )
      // }
    },
    puxaListas: function () {
      this.loading = true
      var p = []
      p.push(this.getUsuarioData('img'))
      p.push(this.getUsuarioData('ID'))
      p.push(this.getUsuario())
      p.push(this.listar('gp_Grupo', 'grp_mnemonico', 'gtipo_ID = 2').then(
        (response) => {
          for (var i = 0; i < response.length; i++) {
            this.asAreas.push({value: response[i].grp_ID, text: response[i].grp_mnemonico})
          }
          return true
        },
        (response) => {
          return false
        }
      ))
      p.push(this.listar('gp_Grupo', 'grp_mnemonico', 'gtipo_ID = 1').then(
        (response) => {
          for (var i = 0; i < response.length; i++) {
            this.osPapeis.push({value: response[i].grp_ID, text: response[i].grp_mnemonico})
          }
          return true
        },
        (response) => {
          return false
        }
      ))
      p.push(this.getUsuarioArea())
      Promise.all(p).then(
        (r) => {
          this.loading = false
        },
        (r) => {
          this.loading = false
        }
      )
    },
    editUsuario: function () {
      if (this.usuario.email === '') {
        this.$notify({
          type: 'error',
          title: 'E-mail',
          text: 'O e-mail é obrigatório e precisa ser válido.'
        })
        return false
      }
      if (this.set_password) {
        if (this.usuario.password2 && this.usuario.password !== this.usuario.password2) {
          this.$notify({
            type: 'error',
            title: 'Senha incorreta',
            text: 'A senha não bate com aquela que redigitou.'
          })
          return false
        }
        if (this.usuario.password && this.usuario.password.length < 6) {
          this.$notify({
            type: 'error',
            title: 'Senha inválida',
            text: 'A senha precisa conter pelo menos 6 caracteres.'
          })
          return false
        }
      }
      if (this.image) {
        this.usuariodata.usr_img = this.image
      }
      this.atualizar('user', this.usuario, this.$route.params.id, 'system', '1').then(
        (response) => {
          var id = this.$route.params.id
          if (id > 0 && this.ownerid === this.$session.get('id')) {
            this.atualizar('gp_User', {resource: [{ usr_img: this.usuariodata.usr_img.toString() }]}, 'api_ID = ' + id, '', '1').then(
              (response) => {
              }, (response) => {}
            )
            if (this.usuarioArea || this.usuarioPapel) {
              // Limpa as areas anteriores
              var whereIn = []
              for (var i = 0; i < this.asAreas.length; i++) {
                whereIn.push(this.asAreas[i].value)
              }
              for (var i = 0; i < this.osPapeis.length; i++) {
                whereIn.push(this.osPapeis[i].value)
              }
              this.remover('gp_User_Grupo', '(user_ID = ' + this.usuariodata['usr_ID'] + ') AND (grp_ID IN (' + whereIn.join(',') + '))').then(
                (response) => {
                  this.inserir('gp_User_Grupo', {resource: [{user_ID: this.usuariodata['usr_ID'], grp_ID: this.usuarioArea}]}, '', '1').then(
                    (response) => {
                      this.inserir('gp_User_Grupo', {resource: [{user_ID: this.usuariodata['usr_ID'], grp_ID: this.usuarioPapel}]}, '', '1').then(
                        (response) => {},
                        (response) => {}
                      )
                    },
                    (response) => {}
                  )
                },
                (response) => {}
              )
            }
            this.$router.push('/admin/usuarios/listar')
          }
        }, (response) => {})
    }
  },
  components: {
    vSelect,
    Loading
  }
}
</script>